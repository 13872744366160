import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import CasinoLoyaltyProgramNextLevelInfo from // eslint-disable-next-line max-len
'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramNextLevelInfo/CasinoLoyaltyProgramNextLevelInfo.vue';
import CasinoLoyaltyProgramTitle from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramTitle/CasinoLoyaltyProgramTitle.vue';
import CasinoLoyaltyLobby from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyLobby/CasinoLoyaltyLobby.vue';
import CasinoLoyaltyProgramSwiper from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramSwiper/CasinoLoyaltyProgramSwiper.vue';
import ModalWindowHeader from 'web/src/components/Modal/ModalWindowHeader/ModalWindowHeader.vue';
import CasinoLoyaltyProgramIcon from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramIcon/CasinoLoyaltyProgramIcon.vue';
import BonusLoyaltyProgramBalance from 'web/src/modules/bonuses/components/BonusLoyaltyProgramBalance/BonusLoyaltyProgramBalance.vue';
import CasinoLoyaltyProgramCurrentLevelCard from // eslint-disable-next-line max-len
'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramCurrentLevelCard/CasinoLoyaltyProgramCurrentLevelCard.vue';
import CasinoLoyaltyProgramList from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramList/CasinoLoyaltyProgramList.vue';
import DefaultModal from 'web/src/components/Modal/DefaultModal/DefaultModal.vue';
import { CasinoLoyaltyProgramCardSizes } from 'web/src/modules/casino-loyalty-program/store/types';
import { useCasinoLoyaltyProgramRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramRoutePage',
    props: {
        id: {
            default: '0'
        }
    },
    setup (__props) {
        const props = __props;
        const { selectedId, showLevelDescription, currentPointsToString, remainingPoints, progressProps, nextLevelId, programListProperties, currentPoints, casinoLoyaltyProgramInfoRouteName, modalSelector, modalProps, onClose, headerProperties, onCloseAll, iconProps, modalIconSize, titleProps, balanceProps, descriptionText, currentLevelCardProperties, currentLevelId, levelSettingsWithStatus } = useCasinoLoyaltyProgramRoutePage(props);
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['casino-loyalty-program-page']]: true,
                    [_ctx.$style['casino-loyalty-program-page--no-scroll']]: _unref(selectedId)
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-page__header'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['casino-loyalty-program-page__header-title'])
                    }, _toDisplayString(_ctx.$t('WEB2_LOYALTY_PROGRAM_MY_LEVEL')), 3)
                ], 2),
                _createVNode(CasinoLoyaltyProgramCurrentLevelCard, _mergeProps(_unref(currentLevelCardProperties), {
                    class: _ctx.$style['casino-loyalty-program-page__current-level'],
                    onShowDescription: _cache[0] || (_cache[0] = ($event)=>_unref(showLevelDescription)(_unref(currentLevelId)))
                }), null, 16, [
                    "class"
                ]),
                _createVNode(CasinoLoyaltyProgramNextLevelInfo, {
                    "current-points": _unref(currentPointsToString),
                    "remaining-points": _unref(remainingPoints),
                    progress: _unref(progressProps)(_unref(nextLevelId) || 0),
                    "level-data-list": _unref(programListProperties)(_unref(nextLevelId) || 0),
                    "level-title": _unref(currentLevelCardProperties).nextLevelTitle
                }, null, 8, [
                    "current-points",
                    "remaining-points",
                    "progress",
                    "level-data-list",
                    "level-title"
                ]),
                _createVNode(CasinoLoyaltyProgramSwiper, {
                    cards: _unref(levelSettingsWithStatus),
                    "current-points": _unref(currentPoints),
                    "has-navigation": true,
                    "no-header-title-padding": false,
                    "card-size": _unref(CasinoLoyaltyProgramCardSizes).SIZE_124,
                    "no-padding": "",
                    onClickCard: _unref(showLevelDescription)
                }, null, 8, [
                    "cards",
                    "current-points",
                    "has-navigation",
                    "card-size",
                    "onClickCard"
                ]),
                _createVNode(CasinoLoyaltyLobby, {
                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-page__games'])
                }, null, 8, [
                    "class"
                ]),
                _createVNode(_component_router_link, {
                    to: {
                        name: _unref(casinoLoyaltyProgramInfoRouteName)
                    },
                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-page__how-it-works-link'])
                }, {
                    default: _withCtx(()=>[
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_LOYALTY_PROGRAM_CASINO_INFO')), 1)
                        ]),
                    _: 1
                }, 8, [
                    "to",
                    "class"
                ]),
                _unref(selectedId) ? (_openBlock(), _createBlock(_Teleport, {
                    key: 0,
                    to: _unref(modalSelector)
                }, [
                    _createVNode(DefaultModal, _mergeProps(_unref(modalProps), {
                        class: _ctx.$style['casino-loyalty-program-page__modal-description'],
                        onClose: _unref(onClose)
                    }), {
                        header: _withCtx(()=>[
                                _createVNode(ModalWindowHeader, _mergeProps(_unref(headerProperties), {
                                    onPrefixClick: _unref(onClose),
                                    onSuffixClick: _unref(onCloseAll)
                                }), null, 16, [
                                    "onPrefixClick",
                                    "onSuffixClick"
                                ])
                            ]),
                        content: _withCtx(()=>[
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-page__description'])
                                }, [
                                    _createVNode(CasinoLoyaltyProgramIcon, _mergeProps(_unref(iconProps), {
                                        size: _unref(modalIconSize)
                                    }), null, 16, [
                                        "size"
                                    ]),
                                    _createElementVNode("div", null, [
                                        _createVNode(CasinoLoyaltyProgramTitle, _normalizeProps(_guardReactiveProps(_unref(titleProps))), null, 16),
                                        _createVNode(BonusLoyaltyProgramBalance, _mergeProps(_unref(balanceProps), {
                                            "has-no-hover": ""
                                        }), null, 16)
                                    ]),
                                    _createVNode(CasinoLoyaltyProgramList, _normalizeProps(_guardReactiveProps(_unref(programListProperties)(_unref(selectedId)))), null, 16),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['casino-loyalty-program-page__description-text'])
                                    }, _toDisplayString(_unref(descriptionText)(_unref(selectedId))), 3)
                                ], 2)
                            ]),
                        _: 1
                    }, 16, [
                        "class",
                        "onClose"
                    ])
                ], 8, [
                    "to"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramRoutePage'
                ]
            ]);
        };
    }
});
