// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-loyalty-program-list_iGRUO{width:100%}.casino-loyalty-program-list__wrapper_cjQiZ{display:flex;flex-direction:column;gap:8px}.casino-loyalty-program-list__item_PgmRK{background-color:var(--OpacityLayer1)}.casino-loyalty-program-list--is-dark_nxbwE .casino-loyalty-program-list__item_PgmRK{background-color:var(--Layer2)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-loyalty-program-list": `casino-loyalty-program-list_iGRUO`,
	"casino-loyalty-program-list__wrapper": `casino-loyalty-program-list__wrapper_cjQiZ`,
	"casino-loyalty-program-list__item": `casino-loyalty-program-list__item_PgmRK`,
	"casino-loyalty-program-list--is-dark": `casino-loyalty-program-list--is-dark_nxbwE`
};
export default ___CSS_LOADER_EXPORT___;
