// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-loyalty-program-page--no-scroll_V70UT{overflow-y:inherit}.casino-loyalty-program-page__header_l0WJI{display:flex;align-items:center;justify-content:space-between;padding:18px 18px 18px 8px}.casino-loyalty-program-page__header-title_zh2cL{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;color:var(--TextDefault)}@media(hover:hover)and (pointer:fine){.casino-loyalty-program-page__header-counter__G1lY:hover{cursor:pointer}}.casino-loyalty-program-page__current-level_T6KGk{margin-bottom:16px}html.app__layout--desktop .casino-loyalty-program-page__current-level_T6KGk{margin-bottom:8px}.casino-loyalty-program-page__modal-description_b73Kz{background-color:var(--LoyaltyLevelLayer)}.casino-loyalty-program-page__description_onnZS{display:flex;flex-direction:column;row-gap:16px;align-items:center}.casino-loyalty-program-page__description-text_Ti6vh{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary);text-align:left}html.app__layout--desktop .casino-loyalty-program-page__games_pk5mP{margin-right:0;margin-left:0}.casino-loyalty-program-page__how-it-works-link_WfLj2{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;display:flex;align-items:center;justify-content:center;width:100%;height:44px;margin:0 auto 32px;color:var(--TextPrimary);-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer}@media(hover:hover)and (pointer:fine){.casino-loyalty-program-page__how-it-works-link_WfLj2:hover{color:var(--BrandHighlight)}}html.app__layout--desktop .casino-loyalty-program-page_XMU1Q{padding:16px 24px 0}html.app__os--windows .casino-loyalty-program-page--no-scroll_V70UT{overflow-x:inherit}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-loyalty-program-page--no-scroll": `casino-loyalty-program-page--no-scroll_V70UT`,
	"casino-loyalty-program-page__header": `casino-loyalty-program-page__header_l0WJI`,
	"casino-loyalty-program-page__header-title": `casino-loyalty-program-page__header-title_zh2cL`,
	"casino-loyalty-program-page__header-counter": `casino-loyalty-program-page__header-counter__G1lY`,
	"casino-loyalty-program-page__current-level": `casino-loyalty-program-page__current-level_T6KGk`,
	"casino-loyalty-program-page__modal-description": `casino-loyalty-program-page__modal-description_b73Kz`,
	"casino-loyalty-program-page__description": `casino-loyalty-program-page__description_onnZS`,
	"casino-loyalty-program-page__description-text": `casino-loyalty-program-page__description-text_Ti6vh`,
	"casino-loyalty-program-page__games": `casino-loyalty-program-page__games_pk5mP`,
	"casino-loyalty-program-page__how-it-works-link": `casino-loyalty-program-page__how-it-works-link_WfLj2`,
	"casino-loyalty-program-page": `casino-loyalty-program-page_XMU1Q`
};
export default ___CSS_LOADER_EXPORT___;
