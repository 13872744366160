// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino_loyalty_program_next_level_info__title_woMad{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;padding:18px 8px}.casino_loyalty_program_next_level_info__wrapper__wnEp{display:flex;flex-direction:column;gap:8px;padding:8px;background-color:var(--Layer1);border-radius:5px}.casino_loyalty_program_next_level_info__content_qbJBm{display:flex;align-items:center;justify-content:space-between}.casino_loyalty_program_next_level_info__left_N4yRC{display:flex;gap:8px;align-items:center}.casino_loyalty_program_next_level_info__level-title_JkUg9{font-size:18px;font-weight:500;line-height:24px;letter-spacing:normal;color:var(--TextDefault)}.casino_loyalty_program_next_level_info__right_qrcbA{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino_loyalty_program_next_level_info__title": `casino_loyalty_program_next_level_info__title_woMad`,
	"casino_loyalty_program_next_level_info__wrapper": `casino_loyalty_program_next_level_info__wrapper__wnEp`,
	"casino_loyalty_program_next_level_info__content": `casino_loyalty_program_next_level_info__content_qbJBm`,
	"casino_loyalty_program_next_level_info__left": `casino_loyalty_program_next_level_info__left_N4yRC`,
	"casino_loyalty_program_next_level_info__level-title": `casino_loyalty_program_next_level_info__level-title_JkUg9`,
	"casino_loyalty_program_next_level_info__right": `casino_loyalty_program_next_level_info__right_qrcbA`
};
export default ___CSS_LOADER_EXPORT___;
