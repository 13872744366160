import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "loyalty-program-info"
};
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramInfoRoutePage',
    setup (__props) {
        const analytics = useAnalytics();
        const onLoyaltyBlockClick = (sectionName)=>{
            analytics.push(AnalyticsEvent.Z_LOYALTY_PROGRAM_INFO, {
                loyaltyProgram: {
                    programInfo: {
                        clickSubject: sectionName
                    }
                }
            });
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(VCmsContent, {
                    "cms-key": "WEB2_LOYALTY_PROGRAM_INFO_V2",
                    onLoyaltyBlockClick: onLoyaltyBlockClick
                })
            ])), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramInfoRoutePage'
                ]
            ]);
        };
    }
});
